<template>
  <columns-selector
    :default-columns="defaultColumns"
    :column-configs="columnConfigs"
    :selected-column-config-initial="initialColumnConfig"
    :set-default-config="setDefaultConfig"
    :config-type="configType"
    :config-key="settingsKey"
    @new="onNewColumnConfig"
    @save="onSaveColumnConfig"
    @remove="removeConfig"
  />
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { COLUMN_CONFIG, SAVE_COLUMN_CONFIG } from '@/store/modules/filters/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import AVAILABLE_SETTINGS from '@/model/shared/user/availableSettings';
import ColumnsSelector from '@/components/molecules/modules/Analytics/ColumnsSelector/ColumnsSelector';
import apiRequest from '@/utils/apiRequest';
import Settings from '@/model/shared/user/Settings';

export default {
  name: 'StoreColumnSelector',
  components: {
    ColumnsSelector,
  },
  inject: ['getUserSettings', 'updateUserSettings'],
  props: {
    defaultColumns: {
      type: Array,
      required: true,
    },
    setDefaultConfig: {
      type: Object,
      default: null,
    },
    configType: {
      type: String,
      default: () => null,
    },
    initialByDefault: {
      type: Boolean,
      defalut: () => false,
    },
  },
  data: () => ({
    userSettings: new Settings(),
    columnConfigs: [],
  }),
  computed: {
    ...mapGetters({
      getStoredColumnConfig: COLUMN_CONFIG,
    }),
    settingsKey() {
      return `${AVAILABLE_SETTINGS.ANALYTICS}-${this.$route.name}`;
    },
    localStorageConfig() {
      const userSettingsFromLocalStorage = localStorage.getItem(this.settingsKey);
      if (userSettingsFromLocalStorage) {
        try {
          return JSON.parse(userSettingsFromLocalStorage);
        } catch {
          return null;
        }
      }
      return null;
    },
    storedColumnConfig() {
      return this.getStoredColumnConfig(this.settingsKey);
    },
    userColumnConfig() {
      return this.userSettings.getContext(this.settingsKey);
    },
    initialColumnConfig() {
      if (this.initialByDefault) return null;
      if (this.localStorageConfig) {
        const { id } = this.localStorageConfig;
        if (id && this.userColumnConfig?.find) {
          return this.userColumnConfig.find(column => column.id === id);
        }
        return this.localStorageConfig;
      }
      return this.getStoredColumnConfig(this.settingsKey);
    },
  },
  async created() {
    await this.loadColumnConfigs();
    this.setColumnConfigs();
    this.initializeUserAnalyticsSettings();
  },
  methods: {
    ...mapActions({
      saveColumnConfig: SAVE_COLUMN_CONFIG,
      createToast: CREATE_TOAST,
    }),
    async loadColumnConfigs() {
      await apiRequest(async () => {
        this.userSettings = await this.getUserSettings();
      }).catch(() => {
        this.createToast(Toast.error('Settings not load', 'Error with the user settings'));
      });
    },
    setColumnConfigs() {
      if (this.userSettings.hasContext(this.settingsKey)) {
        this.updateColumnConfigs();
      }
    },
    initializeUserAnalyticsSettings() {
      if (!this.userSettings?.hasContext(this.settingsKey)) {
        this.userSettings.setContext(this.settingsKey, null);
      }
    },
    updateColumnConfigs() {
      this.columnConfigs = this.userSettings.getContext(this.settingsKey).toArray();
    },
    /**
     * @param {import('@/model/shared/user/ColumnSetting').default} config
     */
    async onNewColumnConfig(config) {
      this.onSaveColumnConfig(config);
      this.userSettings.pushConfigByContext(this.settingsKey, config);
      await apiRequest(async () => {
        await this.updateSettings();
      }).catch(() => {
        this.createToast(Toast.error('Settings not save', 'Error saving the user settings'));
      });
      this.setColumnConfigs();
    },
    async removeConfig(configToRemove) {
      this.userSettings.getContext(this.settingsKey).removeConfig(configToRemove.id);

      await apiRequest(async () => {
        await this.updateSettings();
      }).catch(() => {
        this.createToast(Toast.error('Settings not save', 'Error saving the user settings'));
      });
      this.updateColumnConfigs();
    },
    async updateSettings() {
      await apiRequest(async () => {
        await this.updateUserSettings(this.userSettings);
        this.createToast(Toast.success('Settings saved', 'Settings were updated successfully.'));
      }).catch(error => {
        this.createToast(Toast.error('Settings not updated', error.message));
      });
    },
    /**
     * @param {import('@/model/shared/user/ColumnSetting').default} config
     */
    onSaveColumnConfig(config) {
      localStorage.setItem(this.settingsKey, JSON.stringify(config));
      this.saveColumnConfig({ idView: this.settingsKey, config });
      this.onChangeConfig(config);
    },
    /**
     * @param {import('@/model/shared/user/ColumnSetting').default} config
     */
    onChangeConfig(config) {
      this.$emit('change', config.columns);
    },
  },
};
</script>
