var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pb-2 font-bold" }, [_vm._t("title")], 2),
      _c("sun-search-input", {
        staticClass: "mb-2 px-1",
        attrs: { placeholder: "Search", "class-input": "text-sm" },
        on: { search: _vm.onSearch },
      }),
      _c("sun-multi-checkbox", {
        key: "mc-" + _vm.keyMultiCheckbox,
        staticClass: "text-xs",
        attrs: {
          values: _vm.dataMultiCheckbox,
          selected: _vm.visibleList,
          "checkbox-all": _vm.showCheckboxAll,
        },
        on: { change: _vm.handlerMultiCheckbox },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }