<template>
  <div>
    <div class="pb-3 font-bold">
      <slot name="title"></slot>
    </div>
    <div>
      <draggable v-model="list" class="list-group" tag="ul" @input="changeList">
        <transition-group key="as" type="transition" name="flip-list">
          <li
            v-for="element in list"
            :key="element.value"
            class="flex flex-row items-center p-2 mb-1 bg-gray-300 hover:bg-gray-400 shadow border cursor-move text-xs"
          >
            <drag-points class="pr-1" />
            {{ element.name }}
          </li>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
import DragPoints from '@/components/icons/DragPoints';

export default {
  name: 'DraggableList',
  components: {
    DragPoints,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    list: [],
  }),
  computed: {
    visibleList() {
      return this.list.filter(item => item.visible);
    },
  },
  watch: {
    value: 'setList',
  },
  created() {
    this.setList(this.value);
  },
  methods: {
    setList(value) {
      this.list = value;
    },
    changeList(list) {
      this.setList(list);
      this.$emit('input', list);
    },
  },
};
</script>
