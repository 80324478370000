<template>
  <div>
    <div class="pb-2 font-bold">
      <slot name="title"></slot>
    </div>
    <sun-search-input class="mb-2 px-1" placeholder="Search" :class-input="'text-sm'" @search="onSearch" />
    <sun-multi-checkbox
      :key="'mc-' + keyMultiCheckbox"
      class="text-xs"
      :values="dataMultiCheckbox"
      :selected="visibleList"
      :checkbox-all="showCheckboxAll"
      @change="handlerMultiCheckbox"
    />
  </div>
</template>

<script>
export default {
  name: 'ListSelector',
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    keyMultiCheckbox: 0,
    filterQuery: '',
    showCheckboxAll: true,
  }),
  computed: {
    filteredList() {
      return this.value.filter(item => item.name.toLowerCase().indexOf(this.filterQuery.toLowerCase()) !== -1);
    },
    visibleList() {
      return this.value.filter(item => item.visible);
    },
    dataMultiCheckbox() {
      const list = this.filterQuery ? this.filteredList : this.value;
      return list.slice().sort((a, b) => a.order - b.order);
    },
  },
  created() {
    this.value.forEach(column => {
      if (column.subtitle) {
        column.name = column.name.concat(' ', column.subtitle);
      }
    });
  },
  methods: {
    onSearch(value) {
      this.filterQuery = value;
      this.showCheckboxAll = !value;
      this.keyMultiCheckbox++;
    },
    handlerMultiCheckbox(checkedItems) {
      this.value.forEach(item => {
        item.visible = !!checkedItems.find(checked => checked.name === item.name);
      });
      this.$emit('input', this.value);
    },
  },
};
</script>
