var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "pb-3 font-bold" }, [_vm._t("title")], 2),
    _c(
      "div",
      [
        _c(
          "draggable",
          {
            staticClass: "list-group",
            attrs: { tag: "ul" },
            on: { input: _vm.changeList },
            model: {
              value: _vm.list,
              callback: function ($$v) {
                _vm.list = $$v
              },
              expression: "list",
            },
          },
          [
            _c(
              "transition-group",
              { key: "as", attrs: { type: "transition", name: "flip-list" } },
              _vm._l(_vm.list, function (element) {
                return _c(
                  "li",
                  {
                    key: element.value,
                    staticClass:
                      "flex flex-row items-center p-2 mb-1 bg-gray-300 hover:bg-gray-400 shadow border cursor-move text-xs",
                  },
                  [
                    _c("drag-points", { staticClass: "pr-1" }),
                    _vm._v(" " + _vm._s(element.name) + " "),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }