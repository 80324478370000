/**
 * Breakdown
 *
 * @class
 * @public
 */
export default class Breakdown {
  /**
   * @constructor
   * @property @param {string} primary
   * @property @param {string|null} secondary
   */
  constructor(primary, secondary = undefined) {
    this.primary = primary;
    this.subIndex = secondary;
    this.secondary = secondary;
  }
}
