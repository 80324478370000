var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M8.5 6C9.32843 6 10 5.32843 10 4.5C10 3.67157 9.32843 \n    3 8.5 3C7.67157 3 7 3.67157 7 4.5C7 5.32843 7.67157 6 8.5 6Z",
          fill: "#82828C",
        },
      }),
      _c("path", {
        attrs: {
          d: "M8.5 21C9.32843 21 10 20.3284 10 19.5C10 18.6716 9.32843 \n    18 8.5 18C7.67157 18 7 18.6716 7 19.5C7 20.3284 7.67157 21 8.5 21Z",
          fill: "#82828C",
        },
      }),
      _c("path", {
        attrs: {
          d: "M10 12C10 12.8284 9.32843 13.5 8.5 13.5C7.67157 13.5 7 12.8284 \n    7 12C7 11.1716 7.67157 10.5 8.5 10.5C9.32843 10.5 10 11.1716 10 12Z",
          fill: "#82828C",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.5 13.5C16.3284 13.5 17 12.8284 17 12C17 11.1716 16.3284 \n    10.5 15.5 10.5C14.6716 10.5 14 11.1716 14 12C14 12.8284 14.6716 13.5 15.5 13.5Z",
          fill: "#82828C",
        },
      }),
      _c("path", {
        attrs: {
          d: "M17 4.5C17 5.32843 16.3284 6 15.5 6C14.6716 6 14 5.32843 14 \n    4.5C14 3.67157 14.6716 3 15.5 3C16.3284 3 17 3.67157 17 4.5Z",
          fill: "#82828C",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.5 21C16.3284 21 17 20.3284 17 19.5C17 18.6716 16.3284 \n    18 15.5 18C14.6716 18 14 18.6716 14 19.5C14 20.3284 14.6716 21 15.5 21Z",
          fill: "#82828C",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }