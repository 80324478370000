var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("columns-selector", {
    attrs: {
      "default-columns": _vm.defaultColumns,
      "column-configs": _vm.columnConfigs,
      "selected-column-config-initial": _vm.initialColumnConfig,
      "set-default-config": _vm.setDefaultConfig,
      "config-type": _vm.configType,
      "config-key": _vm.settingsKey,
    },
    on: {
      new: _vm.onNewColumnConfig,
      save: _vm.onSaveColumnConfig,
      remove: _vm.removeConfig,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }