<template>
  <div>
    <asterix-dropdown
      ref="dropdown"
      button-classes="flex hover:bg-gray-300 focus:bg-gray-800 focus:text-gray-400 rounded h-10 px-4 items-center"
      :options="availableConfigs"
      @change="onColumnConfigChange"
    >
      <template #default>
        <p class="text-xs">
          <span class="mr-1 font-bold"> Columns: </span>
          <span class="text-orange-500 font-bold">
            {{ selectedColumnConfigName }}
          </span>
        </p>
      </template>

      <template #icon="state">
        <span class="transition-100" :class="!state.visible ? 'rotate-180' : ''">
          <up-svg class="w-2 h-2" :class="state.disabled ? 'text-gray-400' : 'text-gray-500'" />
        </span>
      </template>

      <template #option="slotProps">
        <div
          v-if="isDefaultOption(slotProps) && availableConfigs.length > 2"
          class="h-px w-10/12 bg-gray-400 mx-auto my-2"
        />
        <div
          class="w-full flex justify-between px-3 text-xs font-bold hover:text-white hover:bg-gray-700 rounded-full whitespace-no-wrap"
          :class="{ 'text-white': isOptionSelected(slotProps), 'text-gray-500': !isOptionSelected(slotProps) }"
        >
          {{ slotProps.option.option.name }}
          <div
            v-if="!isDefaultConfig(slotProps.option.option)"
            class="rounded-full hover:bg-gray-600 flex align-middle w-4 cursor-pointer"
            @click.stop.prevent="emitDeleteConfig(slotProps.option.option)"
          >
            <close-svg class="w-2 mx-auto" />
          </div>
        </div>
      </template>
    </asterix-dropdown>

    <transition name="fade">
      <asterix-modal v-if="showModal" :modal-class="modalClass" closable title="Columns" @cancel="onModalClose">
        <template #content>
          <div
            v-if="!isSavingConfig"
            class="flex flex-row flex-wrap text-left justify-center h-full w-full overflow-auto"
          >
            <list-selector
              v-model="columns"
              class="lg:w-2/5 w-full h-full p-5 lg:mr-5 lg:mb-0 mb-5 border shadow border-gray-200 rounded overflow-auto content-div"
            >
              <template #title>
                <span>Metric List</span>
              </template>
            </list-selector>

            <draggable-list
              v-model="columns"
              class="lg:w-1/2 w-full shadow p-5 border border-gray-200 rounded overflow-auto h-full"
            >
              <template #title>
                <span>Columns Selected</span>
              </template>
            </draggable-list>
          </div>
          <div v-else class="px-4 pb-4">
            <header class="pb-2 font-bold">
              <h1>Save column settings</h1>
            </header>
            <div class="text-sm">
              <p>
                You can save your current column settings to speed up future queries. You should give your column
                settings a name
              </p>
              <sun-form class="mt-4">
                <sun-label-group text="Name">
                  <sun-input
                    ref="saveColumnConfigForm"
                    v-model="currentConfigName"
                    placeholder="Name"
                    clearable
                    required
                    text-error="You must provide a name"
                  />
                </sun-label-group>
              </sun-form>
              <SunErrorText v-show="showUniqueError" show-error text-error="Name must be unique"></SunErrorText>
            </div>
          </div>
        </template>
        <template #footer>
          <div v-if="!isSavingConfig" class="flex w-auto flex-wrap py-2 px-4 justify-start">
            <sun-button variant="pill" class="custom-p-1 text-xs" color="orange" @click="emitChangeConfig">
              Apply
            </sun-button>

            <sun-button
              variant="pill"
              class="text-xs ml-2 custom-p-1 bg-gray-700 hover:bg-gray-900"
              color="gray"
              @click="onSaveConfigClick"
            >
              Save settings
            </sun-button>
          </div>

          <div v-else class="flex w-auto flex-wrap py-2 px-4 justify-start">
            <sun-button
              :disabled="hasErrors"
              variant="pill"
              class="text-xs mr-2 custom-p-1"
              color="orange"
              @click="onSaveConfigClick"
            >
              Save
            </sun-button>

            <sun-button variant="pill" class="custom-p-1 text-xs" color="white" @click="isSavingConfig = false">
              Cancel
            </sun-button>
          </div>
        </template>
      </asterix-modal>
    </transition>
    <delete-modal :open="showDeleteModal" @cancel="showDeleteModal = false" @confirm="removeSetting">
      <template #title> Remove Column Setting </template>
      <template #description> {{ removeConfigModalBody }} </template>
    </delete-modal>
  </div>
</template>
<script>
import CloseSvg from '@/components/icons/CloseSvg';
import AsterixDropdown from '@/components/molecules/shared/AsterixDropdown';
import { deepClone } from '@/utils/deepClone';
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import ListSelector from '@/components/molecules/modules/Analytics/ColumnsSelector/ListSelector';
import DraggableList from '@/components/molecules/modules/Analytics/ColumnsSelector/DraggableList';
import DeleteModal from '@/components/organisms/shared/DeleteModal';

export default {
  name: 'ColumnSelector',
  components: {
    AsterixDropdown,
    AsterixModal,
    CloseSvg,
    UpSvg: () => import('@/components/icons/UpSvg.vue'),
    DeleteModal,
    ListSelector,
    DraggableList,
  },
  props: {
    defaultColumns: {
      type: Array,
      default: () => [],
    },
    columnConfigs: {
      type: Array,
      default: () => [],
    },
    selectedColumnConfigInitial: {
      type: Object,
      default: () => null,
    },
    setDefaultConfig: {
      type: Object,
      default: () => null,
    },
    configType: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      showModal: false,
      columns: deepClone(this.defaultColumns),
      customConfig: { name: 'Customize columns', id: null },
      defaultConfig: { name: 'Default', columns: deepClone(this.defaultColumns), id: Date.now() },
      // Handle the modal state
      isSavingConfig: false,
      currentConfigName: null,
      selectedColumnConfig: null,
      configToRemove: null,
      showDeleteModal: false,
      customColumns: [],
    };
  },
  computed: {
    selectedColumnConfigName() {
      return this.selectedColumnConfig?.name || this.defaultConfig.name;
    },
    removeConfigModalBody() {
      return `Are you sure you want to remove the setting "${this.configToRemove.name}"?`;
    },
    columnConfigByType() {
      const columns = deepClone(this.columnConfigs);
      if (this.configType) {
        return columns.filter(col => col.type === this.configType);
      }
      return columns;
    },
    availableConfigs() {
      return [...this.columnConfigByType, this.defaultConfig, this.customConfig];
    },
    showUniqueError() {
      return !!this.columnConfigs.find(
        col => col.name === this.currentConfigName || this.currentConfigName === this.customConfig.name
      );
    },
    hasErrors() {
      return !this.currentConfigName || this.showUniqueError;
    },
    modalClass() {
      return !this.isSavingConfig ? 'asterix-modal-size' : 'save-modal';
    },
  },
  watch: {
    defaultColumns: {
      deep: true,
      handler(value) {
        this.columns = deepClone(value);
      },
    },
    setDefaultConfig: {
      deep: true,
      handler(value) {
        if (value.columns) this.defaultConfig.columns = value.columns;
        this.selectedColumnConfig = this.defaultConfig.name;
      },
    },
  },
  created() {
    if (this.selectedColumnConfigInitial) {
      this.setInfoSelectedColumnConfig(this.selectedColumnConfigInitial);
      this.setColumnConfig(this.selectedColumnConfigInitial);
    }
    this.ensureColumnsOrderAndVisibility();
  },
  methods: {
    // Columns may be defined without order and visibility attributes, so a default value is given
    ensureColumnsOrderAndVisibility() {
      if (this.columns?.length) {
        if (!Object.keys(this.columns[0]).includes('order')) {
          this.columns.forEach((col, index) => (col.order = index));
        }
        if (!Object.keys(this.columns[0]).includes('visible')) {
          this.columns.forEach(col => (col.visible = true));
        }
      }
    },
    isOptionSelected(dropdownOptions) {
      const { option } = dropdownOptions.option;
      const selected = option.id === this.selectedColumnConfig?.id;

      return selected || (!this.selectedColumnConfig && option.id === this.defaultConfig.id);
    },
    isDefaultOption(dropdownOptions) {
      const { option } = dropdownOptions.option;
      return option.id === this.defaultConfig.id;
    },
    onColumnConfigChange(config) {
      this.setInfoSelectedColumnConfig(config);

      if (config?.id === this.customConfig.id) {
        this.showModal = true;
        const columnsSelected = this.columns.filter(column => column.visible).map(column => column.name);
        this.columns = deepClone(this.defaultColumns);
        this.columns.forEach(col => (col.visible = columnsSelected.includes(col.name)));
        this.ensureColumnsOrderAndVisibility();
        return;
      }

      this.setColumnConfig(config);
    },
    setInfoSelectedColumnConfig(config) {
      this.selectedColumnConfig = config;
    },
    setColumnConfig(config) {
      this.columns = config?.columns.map(column => ({ ...column }));
      this.ensureColumnsOrderAndVisibility();
      this.emitChangeConfig();
    },
    onSaveConfigClick() {
      if (this.isSavingConfig) {
        if (!this.showUniqueError) {
          this.emitCreateConfig();
          this.currentConfigName = '';
        }
      } else {
        this.isSavingConfig = true;
      }
    },
    emitCreateConfig() {
      this.onNewColumnConfig({
        columns: deepClone(this.columns),
        id: Date.now(),
        name: this.currentConfigName,
        type: this.configType,
      });

      this.showModal = false;
      this.isSavingConfig = false;
    },
    emitChangeConfig() {
      this.handlerColumns({
        columns: deepClone(this.columns),
        id: this.selectedColumnConfig?.id,
        name: this.selectedColumnConfig?.name,
        type: this.selectedColumnConfig?.type,
      });

      this.showModal = false;
      this.isSavingConfig = false;
    },
    emitDeleteConfig(config) {
      this.$refs.dropdown.toggleDropdown();

      this.onDeleteColumnConfig(config);

      this.showModal = false;
      this.isSavingConfig = false;
    },
    onModalClose() {
      this.showModal = false;
      this.isSavingConfig = false;
    },
    isDefaultConfig(config) {
      return config.id === this.defaultConfig.id || config.id === this.customConfig.id;
    },
    handlerColumns(config) {
      this.saveColumnConfig(config);
    },
    onNewColumnConfig(config) {
      this.onColumnConfigChange(config);
      this.$emit('new', config);
    },
    onDeleteColumnConfig(config) {
      this.configToRemove = config;
      this.showDeleteModal = true;
    },
    removeSetting() {
      this.$emit('remove', this.configToRemove);
      if (this.configToRemove.id === this.selectedColumnConfig?.id) {
        this.onColumnConfigChange(this.defaultConfig);
        this.currentConfigName = this.defaultConfig.name;
      }

      this.showDeleteModal = false;
      this.configToRemove = null;
    },
    saveColumnConfig(config) {
      this.$emit('save', deepClone(config));
    },
  },
};
</script>

<style scoped>
::v-deep .custom-modal {
  overflow: auto !important;
}

.h-3\/5 {
  height: auto;
}

@media (min-width: 1024px) {
  .h-3\/5 {
    height: 60vh;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

::v-deep .save-modal {
  width: auto;
  height: 22rem;
}
</style>
